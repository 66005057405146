import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import './AdminUI.css';
import { createGuestRequest, createPartyRequest, deleteGuestRequest, deletePartyRequest, listGuestsRequest, listPartyRequest, modifyGuestRequest, parseToken, setAllowedExtras } from './partyAdminApi';
import { GrammaticalGender, Person, RequestCreateGuest, ResponseCreateParty, ResponseListGuests, ResponseListParties } from './partyAdminApiTypes';

export const AdminUI: React.FC = () => {

    const [editingParty, setEditingParty] = useState(false);
    const [partyList, setPartyList] = useState<ResponseListParties>([]);
    const [selectedParty, setSelectedParty] = useState<number | undefined>(undefined);
    // eslint-disable-next-line no-restricted-globals
    const adminToken = useMemo(() => parseToken(location.href) ?? "", []);

    const loadPartyList = useCallback(async () => {
        console.log("load party list");
        const listPartyResponse = await listPartyRequest(adminToken);
        if (selectedParty === undefined)
            setSelectedParty(listPartyResponse.length > 0 ? 0 : undefined);
        setPartyList([...listPartyResponse].sort((a, b) => b.created.localeCompare(a.created)));
    }, [adminToken, selectedParty]);

    const deleteUser = async () => {
        if (selectedParty === undefined || !partyList[selectedParty]) return;
        if (!editUserData || (!("_id" in editUserData))) return;
        deleteGuestRequest(adminToken, partyList[selectedParty].name, editUserData);
        loadPartyList();
        dismissBackdrop();
    };

    const editUser = (user?: RequestCreateGuest) => {
        const dialog = document.getElementById("dialog-edit-user") as HTMLDialogElement;
        if (!dialog) return;
        setEditUserData({ coming: null, extra: {}, grammatical_gender: "d", name: "", token: "", ...user });
        dialog.show();
    };

    const editParty = () => {
        const dialog = document.getElementById("dialog-edit-party") as HTMLDialogElement;
        if (!dialog) return;
        setEditingParty(true);
        dialog.show();
    };
    const [editUserData, setEditUserData] = useState<RequestCreateGuest | Person>();

    const createParty = () => {
        const element = document.getElementById("create-party") as HTMLInputElement;
        if (!element) return;
        const partyName = element.value;
        if (!partyName) return;
        createPartyRequest(adminToken, { allowed_extra: {}, name: partyName });
        loadPartyList();
        dismissBackdrop();
    };

    const updateExtras = (partyName: string) => {
        const element = document.getElementById(`payload-${partyName}`) as HTMLInputElement;
        if (!element) return;
        const newExtras = element.value;
        if (newExtras === "deletethis") {
            deletePartyRequest(adminToken, partyName);
        } else {
            const payload = JSON.parse(newExtras);
            setAllowedExtras(adminToken, partyName, payload);
        }
        loadPartyList();
        dismissBackdrop();
    };

    const dismissBackdrop = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.stopPropagation();
        ["dialog-edit-user", "dialog-edit-party"].forEach(elementId => {
            const dialog = document.getElementById(elementId) as HTMLDialogElement;
            dialog.close();
        })
        setEditUserData(undefined);
        setEditingParty(false);
    };

    const saveEditUser = () => {
        if (selectedParty === undefined || !partyList[selectedParty]) return;
        if (!editUserData) return;
        if ("_id" in editUserData) {
            modifyGuestRequest(adminToken, partyList[selectedParty].name, editUserData);
        } else {
            createGuestRequest(adminToken, partyList[selectedParty].name, editUserData);
        }
        loadPartyList();
        dismissBackdrop();
    };

    useEffect(() => {
        if (adminToken === "") return;
        loadPartyList();
    }, [adminToken, loadPartyList]);
    
    return <>
        {editUserData || editingParty ?
            <div id="backdrop" onClick={dismissBackdrop} />
            : null}
        <dialog id="dialog-edit-party">
            <div>
                <span className="lg">Edit Parties</span>
                <div className="dialog-party-table">
                    {partyList.map((p, i)=> <>
                        <b>{p.name}</b>
                        <label htmlFor={`edit-keys-${p.name}`}>ExtraData:</label>
                        <input type="text" id={`payload-${p.name}`} defaultValue={JSON.stringify(p.allowed_extra)}  />
                        <button onClick={() => updateExtras(p.name)}>update</button>
                    </>)}
                </div>
                <span className="danger-hint">To delete a party, enter <pre>deletethis</pre> and press update!</span>
                <div className="dialog-bottom">
                    <span>Create a party:</span>
                    <input type="text" id={"create-party"} />
                    <button onClick={() => { createParty() }}>Create</button>
                </div>
            </div>
        </dialog>
        <dialog id="dialog-edit-user">
            <div>
                {editUserData ?
                    <>
                        <span className="lg">{"_id" in editUserData ? `Editing ${editUserData.name}` : "New Invitation"}</span>
                        <div className="dialog-person-table">
                            <label htmlFor="edit-token">Token</label>
                            <input type="text" id="edit-token" value={editUserData.token} onChange={(e) => { setEditUserData({ ...editUserData, token: e.target.value }) }} />
                            <label htmlFor="edit-name">Name</label>
                            <input type="text" id="edit-name" value={editUserData.name} onChange={(e) => { setEditUserData({ ...editUserData, name: e.target.value }) }} />
                            <label htmlFor="edit-coming">Coming</label>
                            <select id="edit-coming" value={editUserData.coming ?? "null"} onChange={(e) => {
                                const choice = e.target.value as "yes" | "no" | "maybe" | "null";
                                setEditUserData({ ...editUserData, coming: (choice === "null" ? null : choice) });
                            }} >
                                <option value="yes">yes</option>
                                <option value="no">no</option>
                                <option value="maybe">maybe</option>
                                <option value="null"></option>
                            </select>
                            <label htmlFor="edit-gender">Gender</label>
                            <select id="edit-gender" value={editUserData.grammatical_gender} onChange={(e) => {
                                const choice = e.target.value as GrammaticalGender
                                setEditUserData({ ...editUserData, grammatical_gender: choice });
                            }} >
                                <option value="m">m</option>
                                <option value="f">f</option>
                                <option value="d">d</option>
                            </select>
                        </div>
                    </>
                    : "Hab keine user data bekommen."}

                <div className="dialog-bottom">
                    <button onClick={() => { deleteUser() }}>Delete</button>
                    <button onClick={() => { saveEditUser() }}>Save</button>
                </div>
            </div>
        </dialog>
        <div className="content">
            <div className="select-party-label">
                <span>
                    Select a party
                </span>
            </div>
            <div className="select-party-box">
                {partyList.map((p, index) =>
                    <div key={index} onClick={() => setSelectedParty(index)} className={`select-party ${index === selectedParty ? "selected" : ""}`}>
                        <span>{p.name}</span>
                    </div>
                )}
            </div>
            <div title="Create New Party" className="create-party-label" onClick={() => editParty()}>
                <span>
                    🎉
                </span>

            </div>
            <div className="party-box">
                {selectedParty !== undefined ?
                    <PartyUI party={partyList[selectedParty]} adminToken={adminToken} editUser={editUser} />
                    : null}
            </div>
        </div>
    </>
};

export const PartyUI: React.FC<{ party: ResponseCreateParty, adminToken: string, editUser: (user: RequestCreateGuest) => void }> = ({ party, adminToken, editUser }) => {
    const [guests, setGuests] = useState<ResponseListGuests>([]);

    const loadGuests = useCallback(async () => {
        const guests = await listGuestsRequest(adminToken, party.name);
        setGuests(guests);
    }, [adminToken, party.name]);

    const confirmations = useMemo<[number, number, number]>(() => [
        guests.filter(g => g.coming === "yes").length,
        guests.filter(g => g.coming === "no").length,
        guests.filter(g => g.coming === null || g.coming === "maybe").length,
    ], [guests]);

    useEffect(() => {
        loadGuests();
    }, [loadGuests, party]);

    const exportPartyLink = async (user: RequestCreateGuest) => {
        const url = `https://${party.name}.party.leafbla.de/${user.token}`;
        const template = document.getElementById("template");
        if (template instanceof HTMLTextAreaElement && template.value.trim() !== "") {
            const pattern = template.value.replace("%URL", url);;
            navigator.clipboard.writeText(pattern);
        } else {
            navigator.clipboard.writeText(url);
        }
    };
    const genderCount = useMemo(() => [
        guests.filter(g => g.grammatical_gender === "m").length,
        guests.filter(g => g.grammatical_gender === "f").length,
        guests.filter(g => g.grammatical_gender === "d").length
    ], [guests]);
    const genderstring = `${genderCount[0]} ${genderCount[1]} ${genderCount[2] !== 0 ? genderCount[2] : ""}`;

    return <>
        <div>
            <div className="guests">
                <div className="header"><span className="lg">Token </span></div>
                <div className="header"><span className="lg">Name  </span></div>
                <div className="header"><span className="lg">Gender</span><div>{genderstring}</div></div>
                <div className="header"><span className="lg">Coming</span><div className="confirmations">
                    <span>{confirmations[0]}</span>
                    <span>{confirmations[1]}</span>
                    <span>{confirmations[2]}</span>
                </div></div>
                {guests.map((guest, index) => <Fragment key={index}>
                    <div className="guestname" onContextMenu={(e) => { e.preventDefault(); exportPartyLink(guest); }} onClick={() => editUser(guest)}>{guest.token}</div>
                    <div className={`coming-${guest.coming}`} onContextMenu={(e) => { e.preventDefault(); exportPartyLink(guest); }} onClick={() => editUser(guest)}>{guest.name}</div>
                    <div onContextMenu={(e) => { e.preventDefault(); exportPartyLink(guest); }} onClick={() => editUser(guest)}>{guest.grammatical_gender}</div>
                    <div onContextMenu={(e) => { e.preventDefault(); exportPartyLink(guest); }} onClick={() => editUser(guest)}>{guest.coming ?? "?"}</div>
                </Fragment>
                )}
            </div>
        </div>
        <div className="add-guest">
            <div>
                <span className="tip">Did you know: Contextmenu copies the invite link!</span>
                <textarea placeholder="Use %URL as substitution" id="template" ></textarea>
            </div>
            <span className="lg add-guest-button" onClick={() => editUser({ coming: null, extra: {}, grammatical_gender: "m", name: "", token: "" })}>
                🏃
            </span>
        </div>
    </>;
};