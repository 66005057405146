import { AllowedExtraLengths, Person, RequestCreateGuest, RequestCreateParty, ResponseCreateParty, ResponseListGuests, ResponseListParties } from "./partyAdminApiTypes";

export const parseToken = (uri: string): string | undefined => {
    const x = uri.match(/https?:\/\/party\.leafbla\.de\/(?<token>.+)/);
    if (x === null || x.groups === undefined) return;
    const token = x.groups["token"];
    if (!token) return;
    return token;
};

const apiUrl = (adminToken : string): string => {
    return `https://party.leafbla.de/api/${adminToken}`;
};

export const listPartyRequest = async (adminToken: string): Promise<ResponseListParties> => {
    const result = await fetch(`${apiUrl(adminToken)}`, { method: "get" });
    if (!result.ok) throw new Error("Error sending listPartyRequest");
    const data = await result.json();
    return data as ResponseListParties;
};

export const createPartyRequest = async (adminToken: string, payload: RequestCreateParty): Promise<ResponseCreateParty> => {
    const result = await fetch(`${apiUrl(adminToken)}`, { method: "post", body: JSON.stringify(payload), headers: { "Content-Type": "application/json" } });
    if (!result.ok) throw new Error("Error sending createPartyRequest");
    const data = await result.json();
    return data as ResponseCreateParty;
};

export const listGuestsRequest = async (adminToken: string, partyName: string): Promise<ResponseListGuests> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}`, { method: "get" });
    if (!result.ok) throw new Error("Error sending listGuestRequest");
    const data = await result.json();
    return data as ResponseListGuests;
};

export const createGuestRequest = async (adminToken: string, partyName: string, payload: RequestCreateGuest): Promise<Person> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}`, { method: "post", body: JSON.stringify(payload), headers: { "Content-Type": "application/json" } });
    if (!result.ok) throw new Error("Error sending createGuestRequest");
    const data = await result.json();
    return data as Person;
};

export const modifyGuestRequest = async (adminToken: string, partyName: string, payload: Person): Promise<Person> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}/${payload._id}`, { method: "PATCH", body: JSON.stringify(payload), headers: { "Content-Type": "application/json" } });
    if (!result.ok) throw new Error("Error sending createGuestRequest");
    const data = await result.json();
    return data as Person;
};

export const deleteGuestRequest = async (adminToken: string, partyName: string, payload: Person): Promise<void> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}/${payload._id}`, { method: "DELETE", headers: { "Content-Type": "application/json" } });
    if (!result.ok) throw new Error("Error sending createGuestRequest");
};

export const deletePartyRequest = async (adminToken: string, partyName: string): Promise<void> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}`, { method: "DELETE" });
    if (!result.ok) throw new Error("Error sending deletePartyRequest");
};

/**
 * @deprecated This info can already be found in the listPartiesResponse
 */
export const getAllowedExtras = async (adminToken: string, partyName: string): Promise<AllowedExtraLengths> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}/userAllowedExtra`, { method: "get" });
    if (!result.ok) throw new Error("Error getting allowed extras");
    const data = await result.json();
    return data as AllowedExtraLengths;
};

export const setAllowedExtras = async (adminToken: string, partyName: string, payload: AllowedExtraLengths): Promise<void> => {
    const result = await fetch(`${apiUrl(adminToken)}/${partyName}/userAllowedExtra`, { headers: { "Content-Type": "application/json" }, method: "PATCH", body: JSON.stringify(payload) });
    if (!result.ok) throw new Error("Error setting allowed extras");
};


